import AlfonsoBanner from "../Img/DSC02219.jpg";
import AmatesXAlfonso from "../Img/amatesxalf.svg";
import Azulejo from "../Img/azulejo.svg";
import DSC02286 from "../Img/DSC02286.jpg"
import DSC02474 from "../Img/DSC02474.jpg"

const SOA = () => {

    return (
        <>
            <section className="img-bg-soa min-vh-100 d-flex flex-column text-center justify-content-center align-items-center position-relative">
                <div className="overlay2 position-absolute w-100 h-100"></div>
                <div className="container position-relative col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-6">
                    <h1 className="text-white mb-5 lh-1 display-5 fw-light text-center">Mejora la calidad y funcionalidad de tu proyecto con una segunda opinión arquitectónica</h1>
                    <a href="#informacion" className="btn btn-light rounded-0 btn-lg">
                        Cómo funciona
                    </a>
                </div>
            </section>


            <section id="informacion">
                <div className="py-5">
                    <div className="container col-12 col-sm-12 col-md-12 col-xxl-7">
                        <div className="row align-items-center gy-5">
                            {/* Texto */}
                            <div className="col-12 col-md-6">
                                <img
                                    src={AmatesXAlfonso}
                                    alt="Evaluación arquitectónica"
                                    className="img-fluid mb-1"
                                    width={150}
                                />
                                <h6 className="lh-sm py-5">
                                    En <strong>Amates</strong>, como en <strong>Alfonso Nuñez Arquitectos</strong>
                                    <a href="http://www.alfonsonunez.net" target="_blank" rel="noopener noreferrer">

                                    </a>, ofrecemos una <strong>segunda opinión</strong> profesional: una evaluación independiente y objetiva de tu proyecto arquitectónico.
                                    Un especialista, distinto al diseñador original, analiza mejoras y optimización, siempre respetando la visión del arquitecto inicial.
                                </h6>
                                <a type="button" href="/contacto" className="btn btn-primary rounded-0 btn-lg">Contáctanos</a>
                            </div>
                            {/* Imagen */}
                            <div className="col-12 col-md-6 text-center">
                                <div class="ratio ratio-1x1">
                                    <img
                                        src={AlfonsoBanner}
                                        alt="Evaluación arquitectónica"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 bg-ECECEC">
                <div className="container col-12 col-sm-12 col-md-7 col-lg-12 col-xl-7">
                    <div className="row gy-5 align-items-center gx-0 gx-lg-5">
                        {/* Texto alineado a la derecha */}
                        <div className="col-12 col-md-6">
                            <p className="h2 lh-1 fw-bold">
                                Construir es una decisión única que implica una gran inversión.
                            </p>
                            <p className="h6 mt-5">
                                Si estás desarrollando un proyecto de arquitectura o construcción, ya sea una casa habitación, una casa de descanso o una oficina, y deseas una segunda opinión profesional, podemos apoyarte en:
                            </p>
                            <ul className="mt-5 h6">
                                <li className="mb-3">Posibles mejoras a la calidad del proyecto: asegurar que el proyecto en sus dimensiones y funcionalidad se adapten a tus necesidades y objetivos.</li>
                                <li className="mb-3">Evitar posibles diseños costosos o innecesarios: buscar ajustarse a un presupuesto previsto.</li>
                                <li className="mb-3">Revisar propuestas de diseño para evitar mantenimientos onerosos.</li>
                                <li className="mb-3">Identificación de oportunidades de mejora y optimización.</li>
                                <li className="mb-3">Revisión de planos y especificaciones técnicas.</li>
                                <li className="mb-3">Reducción de riesgos y errores.</li>
                                <li className="mb-3">Sugerir aspectos de sustentabilidad energética.</li>
                                <li className="mb-3">Si es conveniente, hacer comentarios sobre la estética de la construcción.</li>
                                <li className="mb-3">Realizar un estudio espacial para confirmar posible mobiliario.</li>
                            </ul>

                        </div>
                        {/* Imagen alineada a la izquierda */}
                        <div className="col-12 col-md-6 text-center">
                            <div className="ratio ratio-16x9 mb-3">
                                <img src={DSC02286} alt="Imagen 1" className="img-fluid" />
                            </div>
                            <div className="ratio ratio-16x9">
                                <img src={DSC02474} alt="Imagen 2" className="img-fluid" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container col-12 col-sm-12 col-md-7 col-lg-12 col-xl-7">
                    <div className="row align-items-center gy-5">
                        {/* Texto alineado a la izquierda */}
                        <div className="col-12 col-md-6">
                            <h3 className="fw-semibold mb-4">Revisión Profesional para tu Proyecto</h3>
                            <p className="mt-3">
                                Ya sea que estés en la etapa inicial o final de tu proyecto, una revisión por un experto puede marcar la diferencia
                                con nuestro <strong>Informe Personalizado de Sugerencias (IPS).</strong>
                            </p>
                            <p>
                                Conoce más sobre cómo podemos ayudarte a llevar tu proyecto al siguiente nivel. Envíanos tus preguntas o comentarios
                                y encontraremos soluciones prácticas y efectivas para ti.
                            </p>
                            <p className="fw-bold">
                                <strong>Servicio de Asesoría Arquitectónica:</strong> $30.00/m²
                                <br /><span className="text-muted">(Ajustable según la complejidad del proyecto)</span>
                            </p>
                            <a type="button" href="/contacto" className="btn btn-primary rounded-0 btn-lg mt-4">Contáctanos</a>
                        </div>

                        {/* Imagen o espacio visual a la derecha */}
                        <div className="col-12 col-md-6 text-center">
                            <img
                                src={Azulejo}
                                alt="Asesoría Arquitectónica"
                                className=" img-fluid"
                                width={300}
                            />
                        </div>
                    </div>
                </div>
            </section>




        </>
    )
}

export default SOA