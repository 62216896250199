import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./Pages/Home"
import Nosotros from './Pages/Nosotros';
import Nav from './Components/Nav';
import Proyectos from './Pages/Proyectos';
import Contacto from './Pages/Contacto';
import Footer from './Components/Footer';
import Blog from './Pages/Blog';
import ProyectoTameplate from './Pages/ProyectoTameplate';
import BlogTameplate from './Pages/BlogTameplate';
import Pescadores from './Pages/ProyectosEnDesarrollo/Pescadores';
import GuiaParaContratar from './Pages/Blogs/GuiaParaContratar'
import ProyectosResidencialHome from './Pages/Proyectos/Residencial/ProyectosResidencialHome';
import ProyectosIyCHome from './Pages/Proyectos/IyC/ProyectosIyCHome';
import ProyectoBTV from './Pages/Proyectos/IyC/ProyectoBTV';
import ProyectoG7TV from './Pages/Proyectos/IyC/ProyectoG7TV';
import ProyectoOIMEC from './Pages/Proyectos/IyC/ProyectoOIMEC';
import ProyectosTurismoHome from './Pages/Proyectos/Turismo/ProyectosTurismoHome';
import ProyectoHotelCC from './Pages/Proyectos/Turismo/ProyectoHotelCC';
import ProyectoHotelEsencia from './Pages/Proyectos/Turismo/ProyectoHotelEsencia';
import ProyectoPalmas14 from './Pages/Proyectos/Residencial/ProyectoPalmas14';
import ProyectoCasaPO from './Pages/Proyectos/Residencial/ProyectoCasaPO';
import ProyectoCasaFRV from './Pages/Proyectos/Residencial/ProyectoCasaFRV';
import ProyectoCasaManantial from './Pages/Proyectos/Residencial/ProyectoCasaManantial';
import ProyectoCasaGV from './Pages/Proyectos/Residencial/ProyectoCasaGV';
import ProyectoCasa8A from './Pages/Proyectos/Residencial/ProyectoCasa8A';
import ProyectoCasaMontanas from './Pages/Proyectos/Residencial/ProyectoCasaMontanas';
import ProyectoCasaPrado from './Pages/Proyectos/Residencial/ProyectoCasaPrado';
import ProyectoCasaVP5 from './Pages/Proyectos/Residencial/ProyectoCasaVP5';
import ProyectosCasasDescandoHome from './Pages/Proyectos/CasasDeDescanso/ProyectosCasasDescandoHome';
import ProyectoAlbercaRoger from './Pages/Proyectos/CasasDeDescanso/ProyectoAlbercaRoger';
import ProyectoCasaChacala from './Pages/Proyectos/CasasDeDescanso/ProyectoCasaChacala';
import ProyectoHerraduraChacala from './Pages/Proyectos/CasasDeDescanso/ProyectoHerraduraChacala';
import ProyectoAlbercaPacifico from './Pages/Proyectos/CasasDeDescanso/ProyectoAlbercaPacifico';
import ProyectoCasa9 from './Pages/Proyectos/CasasDeDescanso/ProyectoCasa9';
import ProyectoCasaOsoGrande from './Pages/Proyectos/CasasDeDescanso/ProyectoCasaOsoGrande';
import ProyectoCasaE from './Pages/Proyectos/CasasDeDescanso/ProyectoCasaE';
import ProyectoCasaSueno from './Pages/Proyectos/CasasDeDescanso/ProyectoCasaSueno';
import ProyectoVillaBelHa from './Pages/Proyectos/CasasDeDescanso/ProyectoVillaBelHa';
import ProyectosEnProcesoHome from './Pages/Proyectos/EnProceso/ProyectosEnProcesoHome';
import ProyectoPescadores from './Pages/Proyectos/EnProceso/ProyectoPescadores';
import ProyectoSayulitaRevolucion from './Pages/Proyectos/EnProceso/ProyectoSayulitaRevolucion';
import ProyectoBuceriasZaragoza from './Pages/Proyectos/EnProceso/ProyectoBuceriasZaragoza';
import ProyectoAlcocerSMA from './Pages/Proyectos/EnProceso/ProyectoAlcocerSMA';
import ProyectoLomasAcueducto from './Pages/Proyectos/EnProceso/ProyectoLomasAcueducto';
import ProyectoCasaTuCasa from './Pages/Proyectos/EnProceso/ProyectoCasaTuCasa';
import AcabadosDeLujo from './Pages/Blogs/AcabadosDeLujo';
import ArquitectoArtLujo from './Pages/Blogs/ArquitectoArtLujo';
import HogarArquitecturaSagrada from './Pages/Blogs/HogarArquitecturaSagrada';
import ArquitecturaInventarDescubrir from './Pages/Blogs/ArquitecturaInventarDescubrir';
import ObjetivoArquitectura from './Pages/Blogs/ObjetivoArquitectura';
import HomeLaPaz from './Pages/HomeLaPaz';
import ModalNewsletter from './Components/ModalNewsletter';
import ProyectoAmates from './Pages/Proyectos/IyC/ProyectoAmates';
import ProyectoHidalgoOficinas from './Pages/Proyectos/IyC/ProyectoHidalgoOficinas';
import ProyectoSigloXXI from './Pages/Proyectos/IyC/ProyectoSigloXXI';
import ProyectoHotelNoPala from './Pages/Proyectos/Turismo/ProyectoHotelNoPala';
import ProyectoHotelBalthazar from './Pages/Proyectos/Turismo/ProyectoHotelBalthazar';
import ProyectoBucerias from './Pages/Proyectos/Turismo/ProyectoBucerias';
import ProyectoElMarquez from './Pages/Proyectos/Turismo/ProyectoElMarquez';
import ProyectoCasaRC from './Pages/Proyectos/Residencial/ProyectoCasaRC';
import ProyectoAlcocer from './Pages/Proyectos/Residencial/ProyectoAlcocer';
import ProyectoDepartamentosAngulo from './Pages/Proyectos/Residencial/ProyectoDepartamentosAngulo';
import ProyectoBuenosAires from './Pages/Proyectos/Residencial/ProyectoBuenosAires';
import ProyectoCubilete from './Pages/Proyectos/Residencial/ProyectoCubilete';
import ProyectoHidalgo from './Pages/Proyectos/Residencial/ProyectoHidalgo';
import ProyectoCasaJurica from './Pages/Proyectos/Residencial/ProyectoCasaJurica';
import ProyectoLaRioja from './Pages/Proyectos/Residencial/ProyectoLaRioja';
import ProyectoSanLuis from './Pages/Proyectos/Residencial/ProyectoSanLuis';
import ProyectoPlenitud from './Pages/Proyectos/Residencial/ProyectoPlenitud';
import ProyectoRanchetteMorelia from './Pages/Proyectos/Residencial/ProyectoRanchetteMorelia';
import ProyectoRenacimientoMonterey from './Pages/Proyectos/Residencial/ProyectoRenacimientoMonterey';
import CasaAlbatros from './Pages/Proyectos/CasasDeDescanso/CasaAlbatros';
import ProyectoCampamentoTortuguero from './Pages/Proyectos/EnProceso/ProyectoCampamentoTortuguero';
import ProyectoDesarrolloChapala from './Pages/Proyectos/EnProceso/ProyectoDesarrolloChapala';
import ProyectoDesarrolloManzanillo from './Pages/Proyectos/EnProceso/ProyectoDesarrolloManzanillo';
import ProyectoLaQuerencia from './Pages/Proyectos/EnProceso/ProyectoLaQuerencia';
import ProyectoSportHabitatPV from './Pages/Proyectos/EnProceso/ProyectoSportHabitatPV';
import ProyectoSanLuisTorre from './Pages/Proyectos/Residencial/ProyectoSanLuisTorre';
import AcuarelasPageDos from './Pages/AcuarelasPageDos';
import BlogSection from './Pages/Blogs/BlogSection';
import VillaBelHa from './Pages/Proyectos/Turismo/VillaBelHa';
import NotFound from './Pages/NotFound';
import AvisoPrivacidad from './Pages/Aviso/AvisoPrivacidad';
import ConsideracionesArqu from './Pages/Blogs/ConsideracionesArqu';
import MonumentoAlArquitecto from './Pages/Blogs/MonumentoAlArquitecto';
import BlogAlfonso from './Pages/Blog-alfonso'
import GraciasBlog from './Pages/Gracias-blog-alfonso'
import SOA from './Pages/SOA';


function App() {



  return (
    <Router>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/proyectos" element={<Proyectos />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/proyecto/palmas14" element={<ProyectoTameplate />} />
        <Route path="/blogtameplate" element={<BlogTameplate />} />
        <Route path="/pescadores" element={<Pescadores />} />
        <Route path="/guia-para-contratar-servicios-arquitectonicos" element={<GuiaParaContratar />} />
        <Route path="/proyectos-residenciales" element={<ProyectosResidencialHome />} />
        <Route path="/proyectos-comerciales" element={<ProyectosIyCHome />} />
        <Route path="/proyecto-bodega-de-tequila-el-viejo" element={<ProyectoBTV />} />
        <Route path="/proyecto-bodega-de-tequila-el-viejo" element={<ProyectoBTV />} />
        <Route path="/proyecto-ganaderia-el-7-torre-vieja" element={<ProyectoG7TV />} />
        <Route path="/proyecto-oficinas-imec" element={<ProyectoOIMEC />} />
        <Route path="/proyectos-hoteles" element={<ProyectosTurismoHome />} />
        <Route path="/proyecto-hotel-casa-cocula" element={<ProyectoHotelCC />} />
        <Route path="/proyecto-hotel-esencia" element={<ProyectoHotelEsencia />} />
        <Route path="/proyecto-palmas-14" element={<ProyectoPalmas14 />} />
        <Route path="/proyecto-residencial-po" element={<ProyectoCasaPO />} />
        <Route path="/proyecto-casa-frv" element={<ProyectoCasaFRV />} />
        <Route path="/proyecto-casa-manantial" element={<ProyectoCasaManantial />} />
        <Route path="/proyecto-casa-gv" element={<ProyectoCasaGV />} />
        <Route path="/proyecto-casa-8a" element={<ProyectoCasa8A />} />
        <Route path="/proyecto-casa-de-las-montanas" element={<ProyectoCasaMontanas />} />
        <Route path="/proyecto-casa-prado" element={<ProyectoCasaPrado />} />
        <Route path="/proyecto-vp5" element={<ProyectoCasaVP5 />} />
        <Route path="/proyectos-playa-y-montana" element={<ProyectosCasasDescandoHome />} />
        <Route path="/proyectos-alberca-roger" element={<ProyectoAlbercaRoger />} />
        <Route path="/casa-sin-fin-chacala" element={<ProyectoCasaChacala />} />
        <Route path="/proyecto-casa-la-herradura-chacala" element={<ProyectoHerraduraChacala />} />
        <Route path="/proyecto-alberca-pacifico" element={<ProyectoAlbercaPacifico />} />
        <Route path="/proyecto-casa9" element={<ProyectoCasa9 />} />
        <Route path="/proyecto-casa-oso-grande-chacala" element={<ProyectoCasaOsoGrande />} />
        <Route path="/proyecto-casa-e" element={<ProyectoCasaE />} />
        <Route path="/proyecto-casa-sueno" element={<ProyectoCasaSueno />} />
        <Route path="/proyecto-villa-bel-ha-playa-del-carmen" element={<ProyectoVillaBelHa />} />
        <Route path="/proyectos-varios" element={<ProyectosEnProcesoHome />} />
        <Route path="/proyecto-pescadores" element={<ProyectoPescadores />} />
        <Route path="/proyecto-sayulita-revolucion" element={<ProyectoSayulitaRevolucion />} />
        <Route path="/proyecto-bucerias-zaragoza" element={<ProyectoBuceriasZaragoza />} />
        <Route path="/proyecto-alcocer-sma" element={<ProyectoAlcocerSMA />} />
        <Route path="/proyecto-casa-lomas-acueducto" element={<ProyectoLomasAcueducto />} />
        <Route path="/proyecto-tapalpa-c4" element={<ProyectoCasaTuCasa />} />
        <Route path="/acabados-de-lujo" element={<AcabadosDeLujo />} />
        <Route path="/el-arquitecto-articulo-de-lujo" element={<ArquitectoArtLujo />} />
        <Route path="/el-hogar-arquitectura-sagrada" element={<HogarArquitecturaSagrada />} />
        <Route path="/arquitectura-inventar-o-descubrir" element={<ArquitecturaInventarDescubrir />} />
        <Route path="/el-objetivo-no-es-la-arquitectura-sino-lo-que-permitimos-que-suceda-en-ella" element={<ObjetivoArquitectura />} />
        <Route path="/consideraciones-sobre-arquitectura" element={<ConsideracionesArqu />} />
        <Route path="/la-paz" element={<HomeLaPaz />} />
        <Route path="/newsletter" element={<ModalNewsletter />} />
        <Route path="/proyecto-amates" element={<ProyectoAmates />} />
        <Route path="/proyecto-hidalgo-oficinas" element={<ProyectoHidalgoOficinas />} />
        <Route path="/proyecto-siglo21" element={<ProyectoSigloXXI />} />
        <Route path="/proyecto-hotel-nopala" element={<ProyectoHotelNoPala />} />
        <Route path="/proyecto-balthazar" element={<ProyectoHotelBalthazar />} />
        <Route path="/proyecto-bucerias" element={<ProyectoBucerias />} />
        <Route path="/proyecto-el-marquez" element={<ProyectoElMarquez />} />
        <Route path="/proyecto-casa-rc" element={<ProyectoCasaRC />} />
        <Route path="/proyecto-alcocer" element={<ProyectoAlcocer />} />
        <Route path="/proyecto-departamentos-angulo" element={<ProyectoDepartamentosAngulo />} />
        <Route path="/proyecto-buenos-aires" element={<ProyectoBuenosAires />} />
        <Route path="/proyecto-departamentos-cubilete" element={<ProyectoCubilete />} />
        <Route path="/proyecto-departamentos-hidalgo" element={<ProyectoHidalgo />} />
        <Route path="/proyecto-casa-jurica" element={<ProyectoCasaJurica />} />
        <Route path="/proyecto-la-rioja" element={<ProyectoLaRioja />} />
        <Route path="/proyecto-plan-de-san-luis" element={<ProyectoSanLuis />} />
        <Route path="/proyecto-departamentos-plenitud" element={<ProyectoPlenitud />} />
        <Route path="/proyecto-ranchette-morelia" element={<ProyectoRanchetteMorelia />} />
        <Route path="/proyecto-renacimiento-monterrey" element={<ProyectoRenacimientoMonterey />} />
        <Route path="/proyecto-casa-albatros" element={<CasaAlbatros />} />
        <Route path="/proyecto-campamento-tortuguero" element={<ProyectoCampamentoTortuguero />} />
        <Route path="/proyecto-condominio-chapala" element={<ProyectoDesarrolloChapala />} />
        <Route path="/proyecto-desarrollo-manzanillo" element={<ProyectoDesarrolloManzanillo />} />
        <Route path="/proyecto-la-querencia" element={<ProyectoLaQuerencia />} />
        <Route path="/proyecto-sport-habitat" element={<ProyectoSportHabitatPV />} />
        <Route path="/proyecto-san-luis-torre" element={<ProyectoSanLuisTorre />} />
        <Route path="/galeria-acuarelas" element={<AcuarelasPageDos />} />
        <Route path="/blogsection" element={<BlogSection />} />
        <Route path="/proyecto-villa-bel-ha" element={<VillaBelHa />} />
        <Route path="/aviso-de-privacidad" element={<AvisoPrivacidad />} />
        <Route path="/monumento-al-arquitecto-desconocido" element={<MonumentoAlArquitecto />} />
        <Route path="/segunda-opinion-arquitectonica" element={<SOA />} />
        <Route path="/blog-alfonso" element={<BlogAlfonso />} />
        <Route path="/gracias-blog-alfonso" element={<GraciasBlog />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;